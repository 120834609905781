(function(w) {

	"use strict";

	var init = function() {

		HEADER.init();

	};

	w.addEventListener('load', init);

}(window));