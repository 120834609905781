var HEADER = (function(w, d, undefined) {

	'use strict';

	var s = {
			selectors: {
				theWrap: '.header',
				theTrigger: '.header__nav__trigger',
				theTriggerClose: '.header__nav__overlay'
			},
			classes: {
				isVisible: 'offcanvas-is-visible',
				pageScrolled: 'page-is-scrolled'
			},
			theTimer: null,
			theOffset: 300
		},
		els = {},
		init = function() {

			// define elements
			els.theWrap = d.querySelector(s.selectors.theWrap);
			els.theTrigger = d.querySelector(s.selectors.theTrigger);

			// no elements
			if(!els.theWrap || !els.theTrigger) { return; }

			// define some more elements
			els.theTriggerClose = d.querySelector(s.selectors.theTriggerClose);

			// bind events
			els.theTrigger.addEventListener('click', function(e) { toggleIt(); e.preventDefault(); });
			els.theTriggerClose.addEventListener('click', function(e) { hideIt(); e.preventDefault(); });

			w.addEventListener('resize', resizeIt);
			w.addEventListener('scroll', scrollIt);

			// do stuff on init
			scrollIt();

		},
		resizeIt = function() {

			clearTimeout(s.theTimer);
			s.theTimer = setTimeout(hideIt, 300);

		},
		scrollIt = function() {

			w.scrollY % 10 === 0 && d.body.classList.toggle(s.classes.pageScrolled, w.scrollY >= 300);

		},
		toggleIt = function() {

			d.body.classList.contains(s.classes.isVisible) ? hideIt() : showIt();

		},
		showIt = function() {

			d.body.classList.add(s.classes.isVisible);

		},
		hideIt = function() {

			d.body.classList.remove(s.classes.isVisible);

		};

	return {
		init: init
	};

}(window, window.document));